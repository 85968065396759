import { atom, createStore, useAtom } from "jotai";
import type { getAnimalTypes } from "~/general-domain-functions/animal-type.query.server";
import type { UnpackData } from "domain-functions";
import type { getHumanTypes } from "~/general-domain-functions/human-type.query.server";
import type { getEnrichmentTypes } from "~/general-domain-functions/enrichment-type.query.server";

export const globalAnimalTypesAtom = atom<UnpackData<
  typeof getAnimalTypes
> | null>(null);

export const globalHumanTypesAtom = atom<UnpackData<
  typeof getHumanTypes
> | null>(null);

export const globalEnrichmentTypesAtom = atom<UnpackData<
  typeof getEnrichmentTypes
> | null>(null);

export function useGlobalAnimalTypes() {
  return useAtom(globalAnimalTypesAtom);
}

export function useGlobalHumanTypes() {
  return useAtom(globalHumanTypesAtom);
}

export function useGlobalEnrichmentTypes() {
  return useAtom(globalEnrichmentTypesAtom);
}
